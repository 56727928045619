import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.css";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

export const BASE_API = "https://socasoca.victorloumusic.com";

function Confirmar() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [alert, setAlert] = useState(null);
  let { id } = useParams();

  useEffect(() => {
    console.log("useEffect called with id:", id); // Log para depuração

    const confirmEmail = async () => {
      try {
        setAlert({
          type: "success",
          message: "Confirmando...",
        });

        const response = await fetch(`${BASE_API}/api/confirm.php?id=${id}`);
        const data = await response.text();

        setAlert({
          type: data.indexOf("ou conta") === -1 ? "success" : "error",
          message: data,
        });
      } catch (error) {
        setAlert({
          type: "error",
          message: "Houve um erro ao confirmar. Tente novamente mais tarde.",
        });
      }
    };

    confirmEmail();
  }, [id]); // Somente dependência id

  return (
    <div className="container mx-auto overflow-auto">
      <div className="content space-y-6">
        <div className="capa"></div>
        {alert && (
          <div
            className={`alert ${
              alert.type === "success" ? "alert-success" : "alert-error"
            }`}
            role="alert"
          >
            {alert.message}
          </div>
        )}
      </div>
    </div>
  );
}

export default Confirmar;
