import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Confirmar from "./Confirmar";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

ReactPixel.init("3907925519325505", options); // Substitua 'SEU_PIXEL_ID' pelo seu ID do Pixel
ReactPixel.pageView(); // Para registrar uma visualização de página

ReactGA.initialize("AW-861861746"); // Substitua 'SEU_ANALYTICS_ID' pelo seu ID do Google Analytics
ReactGA.pageview(window.location.pathname + window.location.search);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/confirmar/:id",
    element: <Confirmar />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
