import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import "./index.css";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

export const BASE_API = "https://socasoca.victorloumusic.com";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const [formData, setFormData] = useState({
    name: "",
    whatsapp: "",
    email: "",
  });
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataObject = new FormData();

    formDataObject.append("name", formData.name);
    formDataObject.append("whatsapp", formData.whatsapp);
    formDataObject.append("email", formData.email);

    setLoading(true);

    setAlert({
      type: "success",
      message: "Enviando...",
    });

    const salvar_lead = await fetch(`${BASE_API}/api/save_lead.php`, {
      method: "POST",
      body: formDataObject,
    });

    const data = await salvar_lead.text();

    setLoading(false);

    if (!salvar_lead.ok) {
      setAlert({
        type: "error",
        message: "Erro ao enviar o lead, tente novamente.",
      });
      return;
    } else {
      if (String(data).indexOf("Erro") === -1) {
        setAlert({
          type: "success",
          message: data,
        });
        setFormData({ name: "", whatsapp: "", email: "" });
      } else {
        setAlert({
          type: "error",
          message: data,
        });
      }
    }
  };

  return (
    <div className="container mx-auto overflow-auto">
      <div className="content space-y-6">
        <div className="capa"></div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nome"
              className="w-full px-4 py-4 text-center text-white bg-transparent border border-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-accent"
              required
              disabled={loading}
            />
          </div>
          <div>
            <InputMask
              mask={
                formData.whatsapp.length < 15
                  ? "(99) 9999-99999"
                  : "(99) 99999-9999"
              }
              maskChar={null}
              value={formData.whatsapp}
              onChange={handleChange}
              name="whatsapp"
              disabled={loading}
            >
              {(inputProps) => (
                <input
                  type="text"
                  id="whatsapp"
                  placeholder="WhatsApp"
                  className="w-full px-4 py-4 text-center text-white bg-transparent border border-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-accent"
                  {...inputProps}
                  required
                />
              )}
            </InputMask>
          </div>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="E-mail"
              className="w-full px-4 py-4 text-center text-white bg-transparent border border-white rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-accent"
              required
              disabled={loading}
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-4 text-white bg-accent rounded-lg shadow hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
            disabled={loading}
          >
            Enviar
          </button>
        </form>
        {alert && (
          <div
            className={`alert ${
              alert.type === "success" ? "alert-success" : "alert-error"
            }`}
            role="alert"
          >
            {alert.message}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
